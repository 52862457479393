import React, { useEffect } from 'react';
import styled from 'styled-components';

const AdContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const AdComponent = ({ isMobile, position }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//t1.daumcdn.net/kas/static/ba.min.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AdContainer style={{ position }}>
      {isMobile ? (
        <div className="mobile">
          <ins className="kakao_ad_area" 
            style={{ display: 'block' }} 
            data-ad-unit="DAN-v7Pae2OtH2ednYHU" 
            data-ad-width="320" 
            data-ad-height="100"></ins>
        </div>
      ) : (
        <div className="pc" >
          <ins className="kakao_ad_area" 
            style={{ display: 'block' }} 
            data-ad-unit="DAN-jRB8pa8mYTawvPS0" 
            data-ad-width="728" 
            data-ad-height="90"></ins>
        </div>
      )}
    </AdContainer>
  );
};

export default AdComponent;
