import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  ResponseContainer, ResponseBoxContainer, ResponseBox, Title, ResponseText, 
  ButtonContainer, Button, SliderContainer, CardContainer, CardStyle, ShareButtons, 
  WeatherIconContainer, WeatherWrapper, WeatherTitle, ImageContainer, GraphContainer ,
  SkinAnalysisContainer,
  SkinAnalysisTable,
  SkinAnalysisRow,
  SkinAnalysisCell,
  SkinAnalysisHeader,
} from '../data/responseStyles';
import { ShareButtonGroup } from '../data/responseIcons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, Cell,LabelList } from 'recharts';
import cards from '../data/cards'; // 카드 설정 파일 임포트
import 건성피부 from '../assets/건성피부.png';
import 민감성피부 from '../assets/민감성피부.png';
import 중성피부 from '../assets/중성피부.png';
import 지성피부 from '../assets/지성피부.png';
import faceCards from '../data/faceCards';

const LATITUDE = '37.5665';  // 위치의 위도 (서울의 위도 예시)
const LONGITUDE = '126.9780';  // 위치의 경도 (서울의 경도 예시)

const FaceSkinAnalysisResponsePage = () => {
  const [shuffledCards, setCards] = useState([]);
  const [weatherDescription, setWeatherDescription] = useState('');
  const [weatherIcon, setWeatherIcon] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const navigate = useNavigate();

  const { id, hashedName } = useParams();
  const [response, setResponse] = useState('');
  const [userName, setUserName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [analysisResult, setAnalysisResult] = useState(null);

  useEffect(() => {
    const fetchResponse = async () => {
      try {
        const res = await axios.get(`https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/faceSkinAnalysis/${id}/${hashedName}`);
        const data = res.data;
    
        setResponse(data.chatGptResponse);
        setUserName(data.userName);
        setImageUrl(data.imageUrl);
    
        console.log( data.analysisResult);
        // Use the new JSON format for analysisResult
        const analysisResult = JSON.parse(data.analysisResult);
        console.log(analysisResult);

        if (analysisResult && analysisResult.result) {
          setAnalysisResult(analysisResult.result);
        }
      } catch (error) {
        console.error('Error fetching face analysis response', error);
      }
    };

    const fetchWeather = async () => {
      try {
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${LATITUDE}&lon=${LONGITUDE}&appid=${process.env.REACT_APP_WEATHER_API_KEY}&units=metric`;
        const res = await axios.get(url);
        const weatherDescription = res.data.weather[0].description.toLowerCase();
        const weatherIcon = res.data.weather[0].icon;
        setWeatherDescription(weatherDescription);
        setWeatherIcon(weatherIcon);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    const fetchBackgroundImage = async (weatherDescription) => {
      try {
        const now = new Date();
        const hours = now.getHours();
        const timeOfDay = hours >= 6 && hours < 18 ? 'day' : 'night';
        const query = `${weatherDescription} ${timeOfDay}`;
        const url = `https://api.unsplash.com/search/photos?query=${query}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}&per_page=1`;
        const res = await axios.get(url);
        if (res.data.results && res.data.results.length > 0) {
          setBackgroundImage(res.data.results[0].urls.full);
        } else {
          setBackgroundImage('/images/default.jpg');
        }
      } catch (error) {
        console.error("Error fetching background image:", error);
      }
    };
    fetchResponse();
    fetchWeather();

  },  [id, hashedName]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const kakaoKey = process.env.REACT_APP_KAKAO_API_KEY;
    if (kakaoKey && !window.Kakao.isInitialized()) {
      window.Kakao.init(kakaoKey); // 카카오 앱 키로 초기화
    }

    fetchCardCounts();
  }, []);

  const fetchCardCounts = async () => {
    try {
      const response = await axios.get('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/cards/counts');
      const counts = response.data;

      console.log('Counts from backend:', counts);

      let sortedCards = [...cards].sort((a, b) => {
        const countA = counts[a.to] || 0;
        const countB = counts[b.to] || 0;
        return countB - countA;
      });

      // 복사된 객체를 사용하여 제목을 수정
      sortedCards = sortedCards.map((card, index) => {
        const newCard = { ...card }; // 새로운 객체 생성
        if (index < 3) {
          newCard.title = `[👑BEST${index + 1}👑] ${newCard.title}`;
        }
        return newCard;
      });

      // 페이스 카드와 사주 카드를 나누어 추가
      setCards([...faceCards, ...sortedCards]);
    } catch (error) {
      console.error("Failed to fetch card counts", error);
    }
  };

  const handleRetry = () => {
    navigate('/face-skinAnalysis'); // 여기서 URL을 설정하여 페이지 이동
  };
  const handleMemu = () => {
    navigate('/'); // 여기서 URL을 설정하여 페이지 이동
  };
  const shareTitle = `${userName}님의 퍼스널 컬러 진단 결과는..?`;
  const shareDescription = `${userName}님, 퍼스널 컬러 진단 결과를 확인해보세요!`;
  const shareImageUrl = imageUrl; // 공유할 이미지 URL
  const shareUrl = window.location.href; // 현재 페이지 URL 가져오기

  const handleShare = () => {
    navigator.share({
      title: shareTitle,
      text: shareDescription,
      url: shareUrl,
    });
  };

  const handleKakaoShare = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: shareTitle,
        description: shareDescription,
        imageUrl: shareImageUrl, // 공유할 이미지 URL
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
      buttons: [
        {
          title: '자세히 보기',
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
      ],
    });
  };

  const settings = {
    dots: false, // 점 표시 없애기
    infinite: true,
    speed: 500,
    slidesToShow: 3, // 기본 설정: 한 줄에 3개의 카드
    slidesToScroll: 1, // 기본 설정: 한 번에 1개의 카드 스크롤
    arrows: true,
    autoplay: true, // 자동 슬라이드 넘김
    autoplaySpeed: 4000, // 자동 슬라이드 넘김 속도 (4초)
    responsive: [
      {
        breakpoint: 768, // 화면이 768px 이하일 때
        settings: {
          slidesToShow: 1, // 한 줄에 1개의 카드
          slidesToScroll: 1, // 한 번에 1개의 카드 스크롤
          arrows: false, // 모바일에서 화살표 숨기기 (선택사항)
        }
      }
    ]
  };

  const handleFacebookShare = () => {
    const shareUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareDescription)}`, 'facebook-share-dialog', 'width=800,height=600');
  };

  const handleTwitterShare = () => {
    const shareUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareDescription)}`, 'twitter-share-dialog', 'width=800,height=600');
  };

  const handleEmailShare = () => {
    const shareUrl = window.location.href;
    window.location.href = `mailto:?subject=${encodeURIComponent(shareTitle)}&body=${encodeURIComponent(`${shareDescription} 링크: ${shareUrl}`)}`;
  };

  const handleInstagramShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('URL이 복사되었습니다.');
    });
  };
  
  const handleUrlCopy = () => {
    const shareUrl = window.location.href;
    navigator.share({
      title: shareTitle,
      text: shareDescription,
      url: shareUrl,
    });
  };

  const formatResponseText = (text) => {
    // **을 <strong>으로 변환하고, 줄바꿈을 <br>로 변환
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\n/g, '<br />');

    // -로 시작하는 라인을 <ul>과 <li>로 변환
    formattedText = formattedText.replace(/^- (.*)$/gm, '<li>$1</li>');

    // <li>가 있으면 <ul>로 감싸기
    if (formattedText.includes('<li>')) {
      formattedText = formattedText.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>');
    }

    return { __html: formattedText };
  };

    const data = analysisResult
    ? [
        { name: '다크서클', value: analysisResult.dark_circle.value },
        { name: '여드름', value: analysisResult.acne.value },
        { name: '피부반점', value: analysisResult.skin_spot.value },
        { name: '눈가주름', value: analysisResult.crows_feet.value },
        { name: '블랙헤드', value: analysisResult.blackhead.value },
        { name: '미간주름', value: analysisResult.glabella_wrinkle.value },
      ]
    : [];

  const skinTypeStamps = {
    0: 건성피부,
    1: 지성피부,
    2: 중성피부,
    3: 민감성피부,
  };

  const skinTypeStamp = analysisResult ? skinTypeStamps[analysisResult.skin_type.skin_type] : null;

  const COLORS = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
  ];

  const getEyelidDescription = (value) => {
    switch (value) {
      case 0:
        return '일회성 눈꺼풀';
      case 1:
        return '평행 이중 접힘 눈꺼풀';
      case 2:
        return '부채꼴 모양의 이중 접힘 눈꺼풀';
      default:
        return '알 수 없음';
    }
  };

  const renderSkinType = (skinType) => {
    switch (skinType) {
      case 0:
        return '건성 피부';
      case 1:
        return '지성 피부';
      case 2:
        return '중성 피부';
      case 3:
        return '민감성 피부';
      default:
        return '알 수 없음';
    }
  };

  const renderSkinAnalysisResults = (results) => {
    const skinAnalysisItems = [
      { label: '왼쪽 눈꺼풀', value: getEyelidDescription(results.left_eyelids.value) },
      { label: '오른쪽 눈꺼풀', value: getEyelidDescription(results.right_eyelids.value) },
      { label: '눈주머니', value: results.eye_pouch.value === 1 ? '예' : '아니오' },
      { label: '다크서클', value: results.dark_circle.value === 1 ? '예' : '아니오' },
      { label: '이마 주름', value: results.forehead_wrinkle.value === 1 ? '예' : '아니오' },
      { label: '까마귀발', value: results.crows_feet.value === 1 ? '예' : '아니오' },
      { label: '눈의 미세선', value: results.eye_finelines.value === 1 ? '예' : '아니오' },
      { label: '눈썹사이주름', value: results.glabella_wrinkle.value === 1 ? '예' : '아니오' },
      { label: '비강비강주름', value: results.nasolabial_fold.value === 1 ? '예' : '아니오' },
      { label: '피부타입', value: renderSkinType(results.skin_type.skin_type) },
      { label: '이마 모공', value: results.pores_forehead.value === 1 ? '예' : '아니오' },
      { label: '왼쪽 볼 모공', value: results.pores_left_cheek.value === 1 ? '예' : '아니오' },
      { label: '오른쪽 볼 모공', value: results.pores_right_cheek.value === 1 ? '예' : '아니오' },
      { label: '턱 모공', value: results.pores_jaw.value === 1 ? '예' : '아니오' },
      { label: '블랙헤드', value: results.blackhead.value === 1 ? '예' : '아니오' },
      { label: '여드름', value: results.acne.value === 1 ? '예' : '아니오' },
      { label: '두더지', value: results.mole.value === 1 ? '예' : '아니오' },
      { label: '피부 반점', value: results.skin_spot.value === 1 ? '예' : '아니오' },
    ];
  
    return (
      <SkinAnalysisContainer>
        <SkinAnalysisTable>
          <thead>
            <tr>
              <SkinAnalysisHeader>분석 항목</SkinAnalysisHeader>
              <SkinAnalysisHeader>결과</SkinAnalysisHeader>
            </tr>
          </thead>
          <tbody>
            {skinAnalysisItems.map((item, index) => (
              <SkinAnalysisRow key={index}>
                <SkinAnalysisCell>{item.label}</SkinAnalysisCell>
                <SkinAnalysisCell>{item.value}</SkinAnalysisCell>
              </SkinAnalysisRow>
            ))}
          </tbody>
        </SkinAnalysisTable>
      </SkinAnalysisContainer>
    );
  };

return (
  <ResponseContainer /* style={{ backgroundImage: `url(${backgroundImage})` }} */>
    <ResponseBoxContainer>
      <WeatherWrapper>
        <WeatherTitle>오늘의 날씨</WeatherTitle>
        {weatherIcon && (
          <WeatherIconContainer>
            <img src={`/icons/${weatherIcon}.png`} alt={weatherDescription} />
          </WeatherIconContainer>
        )}
      </WeatherWrapper>
      <ResponseBox>
        <Title>{`${userName}님의 퍼스널 컬러 진단 결과는?`}</Title>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <ImageContainer style={{ position: 'relative' }}>
            {imageUrl ? (
              <img src={imageUrl} alt="Uploaded" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            ) : (
              "이미지"
            )}
            {skinTypeStamp && (
              <img
                src={skinTypeStamp}
                alt="Skin Type Stamp"
                style={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-25px',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%'
                }}
              />
            )}
          </ImageContainer>
          {analysisResult && renderSkinAnalysisResults(analysisResult)}
        </div>
        <ResponseText dangerouslySetInnerHTML={formatResponseText(response)} />
      </ResponseBox>
      <ButtonContainer>
        <Button onClick={handleMemu}>⟵ 다른사주 보러가기</Button>
        <Button onClick={handleRetry}>다시하기 ⟳</Button>
        <Button onClick={handleShare}>결과공유하기</Button>
      </ButtonContainer>
      <ShareButtons>
        <ShareButtonGroup
          url={shareUrl}
          title={shareTitle}
          handleKakaoShare={handleKakaoShare}
          handleFacebookShare={handleFacebookShare}
          handleTwitterShare={handleTwitterShare}
          handleEmailShare={handleEmailShare}
          handleInstagramShare={handleInstagramShare}
          handleUrlCopy={handleUrlCopy}
        />
      </ShareButtons>
    </ResponseBoxContainer>
    <SliderContainer>
      <Slider {...settings}>
        {shuffledCards.map((card, index) => (
          <CardContainer key={index}>
            <CardStyle 
              title={card.title} 
              description={card.description} 
              to={card.to} 
              backgroundImage={card.backgroundImage} 
            />
          </CardContainer>
        ))}
      </Slider>
    </SliderContainer>
  </ResponseContainer>
);
};

export default FaceSkinAnalysisResponsePage;
