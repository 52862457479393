import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { HomeContainer, FormContainer, Title, Form, Label, Input, Select, Textarea, Button, SliderContainer, CardContainer, CardStyle, ShareButtons,customStyles } from '../data/styles';
import { ShareButtonGroup } from '../data/icons';
import LoadingModal from './LoadingModal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cards from '../data/cards'; // 카드 설정 파일 임포트
import Modal from 'react-modal';
import CryptoJS from 'crypto-js';
import { Helmet } from 'react-helmet';
import faceCards from '../data/faceCards';

const visitorCountStyles = {
  position: 'relative',
  width: '90%', // 가로 길이 모바일에서도 보기 좋게 줄이기
  maxWidth: '600px', // 최대 가로 길이 설정
  margin: '0 auto', // 가운데 정렬
  textAlign: 'center',
  zIndex: 1000,
  fontSize: '16px', // 글씨 크기 조금 줄이기
  color: '#000', // 글씨 색상을 검정으로 변경
  backgroundColor: 'rgba(255, 192, 203, 0.8)', // 연한 핑크색 배경
  padding: '10px 20px', // 좌우 패딩 추가
  marginBottom: '20px',
  borderRadius: '10px', // 테두리 둥글게
  transition: 'opacity 2s ease-in-out, transform 2s ease-in-out', // opacity와 transform에 대한 transition 설정
  opacity: 0,
  whiteSpace: 'nowrap', // 텍스트가 한 줄에 표시되도록 설정
  overflow: 'hidden', // 텍스트가 넘치지 않도록 설정
  textOverflow: 'ellipsis', // 텍스트가 넘칠 때 ...으로 표시
  transform: 'translateY(-20px)', // 초기 위치를 화면 위로 설정
};

const showVisitorCount = () => {
  const element = document.getElementById('visitor-count');
  if (element) {
    element.style.opacity = 1;
    element.style.transform = 'translateY(0)'; // 화면으로 자연스럽게 내려오도록 설정
  }
};

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= 1900; year--) {
    years.push(year);
  }
  return years;
};

const generateMonthOptions = () => {
  return Array.from({ length: 12 }, (_, i) => i + 1);
};

const generateDayOptions = () => {
  return Array.from({ length: 31 }, (_, i) => i + 1);
};

const DreamInterpretation = () => {
  const [name, setUserName] = useState('');
  const [gender, setGender] = useState('남자');
  const [dreamBirthYear, setDreamBirthYear] = useState('');
  const [dreamBirthMonth, setDreamBirthMonth] = useState('');
  const [dreamBirthDay, setDreamBirthDay] = useState('');
  const [dreamDescription, setDreamDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [shuffledCards, setCards] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [visitorCount, setVisitorCount] = useState(0); // 방문자 수 상태 변수 추가
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const kakaoKey = process.env.REACT_APP_KAKAO_API_KEY;
    if (kakaoKey && !window.Kakao.isInitialized()) {
      window.Kakao.init(kakaoKey); // 카카오 앱 키로 초기화
    }
    fetchCardCounts();

     // 랜덤 방문자 수 생성 및 업데이트
     const updateVisitorCount = () => {
      const randomCount = Math.floor(Math.random() * 100) + 1; // 1부터 100 사이의 랜덤 값 생성
      setVisitorCount(randomCount);
      showVisitorCount(); // 방문자 수 업데이트 후 애니메이션 실행
    };

    updateVisitorCount(); // 컴포넌트 마운트 시 한 번 호출

    const intervalId = setInterval(updateVisitorCount, 3600000); // 1시간마다 호출

    return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 정리
  }, []);

  const fetchCardCounts = async () => {
    try {
      const response = await axios.get('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/cards/counts');
      const counts = response.data;

      let sortedCards = [...cards].sort((a, b) => {
        const countA = counts[a.to] || 0;
        const countB = counts[b.to] || 0;
        return countB - countA;
      });

      // 복사된 객체를 사용하여 제목을 수정
      sortedCards = sortedCards.map((card, index) => {
        const newCard = { ...card }; // 새로운 객체 생성
        if (index < 3) {
          newCard.title = `[👑BEST${index + 1}👑] ${newCard.title}`;
        }
        return newCard;
      });

      // 페이스 카드와 사주 카드를 나누어 추가
      setCards([...faceCards, ...sortedCards]);
    } catch (error) {
      console.error("Failed to fetch card counts", error);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 입력 값 검증
    if (!name || !gender || !dreamBirthYear || !dreamBirthMonth || !dreamBirthDay || !dreamDescription) {
      openModal();
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/dream_interpretation', {
        userName: name,
        gender,
        dreamBirthYear,
        dreamBirthMonth,
        dreamBirthDay,
        dreamDescription,
      });
      const id = res.data.id; 
      const hashedName = CryptoJS.SHA256(name).toString(); // 이름을 해시로 변환
      navigate(`/dreamInterpretationResponse/${id}/${hashedName}`); // Redirect with obfuscated name
    } catch (error) {
      console.error('Error submitting dream interpretation form', error);
    } finally {
      setLoading(false);
    }
  };

  const shareTitle = '오늘의 꿈 해몽!';
  const shareDescription = '오늘 꾼 꿈의 의미가 궁금하신가요? 지금 바로 꿈 해몽을 확인해보세요!';
  const shareImageUrl = 'https://fately.co.kr/꿈해몽.png'; // 공유할 이미지 URL
  const shareLink = {
    mobileWebUrl: window.location.href,
    webUrl: window.location.href,
  };

  const handleKakaoShare = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: shareTitle,
        description: shareDescription,
        imageUrl: shareImageUrl,
        link: shareLink,
      },
      buttons: [
        {
          title: '자세히 보기',
          link: shareLink,
        },
      ],
    });
  };

  const handleFacebookShare = () => {
    const shareUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareDescription)}`, 'facebook-share-dialog', 'width=800,height=600');
  };

  const handleTwitterShare = () => {
    const shareUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareDescription)}`, 'twitter-share-dialog', 'width=800,height=600');
  };

  const handleEmailShare = () => {
    const shareUrl = window.location.href;
    window.location.href = `mailto:?subject=${encodeURIComponent(shareTitle)}&body=${encodeURIComponent(`${shareDescription} 링크: ${shareUrl}`)}`;
  };

  const handleInstagramShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('URL이 복사되었습니다.');
    });
  };
  
  const handleUrlCopy = () => {
    const shareUrl = window.location.href;
    navigator.share({
      title: shareTitle,
      text: shareDescription,
      url: shareUrl,
    });
  };

  const settings = {
    dots: false, // 점 표시 없애기
    infinite: true,
    speed: 500,
    slidesToShow: 3, // 기본 설정: 한 줄에 3개의 카드
    slidesToScroll: 1, // 기본 설정: 한 번에 1개의 카드 스크롤
    arrows: true,
    autoplay: true, // 자동 슬라이드 넘김
    autoplaySpeed: 4000, // 자동 슬라이드 넘김 속도 (4초)
    responsive: [
      {
        breakpoint: 768, // 화면이 768px 이하일 때
        settings: {
          slidesToShow: 1, // 한 줄에 1개의 카드
          slidesToScroll: 1, // 한 번에 1개의 카드 스크롤
          arrows: false, // 모바일에서 화살표 숨기기 (선택사항)
        }
      }
    ]
  };

  const shareUrl = window.location.href; // 현재 페이지 URL
  
  return (
    <HomeContainer>
      <Helmet>
        <title>오늘의 꿈 해몽 - FATELY</title>
        <meta property="og:title" content="오늘의 꿈 해몽 - FATELY" />
        <meta property="og:description" content="FATELY에서 오늘의 꿈 해몽을 무료로 확인해보세요!" />
      </Helmet>
      {/* 방문자 수 표시 */}
      <div id="visitor-count" style={visitorCountStyles}>
        지금 {visitorCount}명이 이 페이지를 보고 있습니다.
      </div>
       {loading && <LoadingModal />}
       <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Alert Modal"
      >
        <h2>알림</h2>
        <p>모든 필드를 입력해주세요.</p>
        <button onClick={closeModal} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007BFF', color: '#fff', border: 'none', cursor: 'pointer' }}>확인</button>
      </Modal>
      <FormContainer>
        <Title>꿈 해몽</Title>
        <Form onSubmit={handleSubmit}>
          <Label>이름</Label>
          <Input type="text" value={name} onChange={(e) => setUserName(e.target.value)} />
          <Label>성별</Label>
          <Select value={gender} onChange={(e) => setGender(e.target.value)}>
            <option value="남자">남자</option>
            <option value="여자">여자</option>
          </Select>
          <Label>출생년</Label>
          <Select value={dreamBirthYear} onChange={(e) => setDreamBirthYear(e.target.value)}>
            <option value="">년도를 선택하세요</option>
            {generateYearOptions().map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </Select>
          <Label>출생월</Label>
          <Select value={dreamBirthMonth} onChange={(e) => setDreamBirthMonth(e.target.value)}>
            <option value="">월을 선택하세요</option>
            {generateMonthOptions().map(month => (
              <option key={month} value={month}>{month}</option>
            ))}
          </Select>
          <Label>출생일</Label>
          <Select value={dreamBirthDay} onChange={(e) => setDreamBirthDay(e.target.value)}>
            <option value="">일을 선택하세요</option>
            {generateDayOptions().map(day => (
              <option key={day} value={day}>{day}</option>
            ))}
          </Select>
          <Label>꿈 내용</Label>
          <Textarea value={dreamDescription} onChange={(e) => setDreamDescription(e.target.value)} rows="5" />
          <Button type="submit">꿈 해몽 하러가기 →</Button>
        </Form>
        <ShareButtons>
          <ShareButtonGroup
            url={shareUrl}
            title={shareTitle}
            handleKakaoShare={handleKakaoShare}
            handleFacebookShare={handleFacebookShare}
            handleTwitterShare={handleTwitterShare}
            handleEmailShare={handleEmailShare}
            handleInstagramShare={handleInstagramShare}
            handleUrlCopy={handleUrlCopy}
          />
        </ShareButtons>
      </FormContainer>
      <SliderContainer>
        <Slider {...settings}>
          {shuffledCards.map((card, index) => (
            <CardContainer key={index}>
              <CardStyle 
                title={card.title} 
                description={card.description} 
                to={card.to} 
                backgroundImage={card.backgroundImage} 
              />
            </CardContainer>
          ))}
        </Slider>
      </SliderContainer>
    </HomeContainer>
  );
};

export default DreamInterpretation;
