import React, { useState, useEffect, useRef } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import axios from 'axios';
import styled from 'styled-components';

const ChatContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 10px;
`;

const ChatHeader = styled.div`
  background-color: #007BFF;
  color: white;
  border-radius: 10px 10px 0 0;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
`;

const MessagesContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const MessageBubble = styled.div`
  display: flex;
  justify-content: ${({ isOwnMessage }) => (isOwnMessage ? 'flex-end' : 'flex-start')};
  padding: 5px;
`;

const MessageContent = styled.div`
  background-color: ${({ isOwnMessage }) => (isOwnMessage ? '#007BFF' : '#FFFFFF')};
  color: ${({ isOwnMessage }) => (isOwnMessage ? '#FFFFFF' : '#000000')};
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  position: relative;
`;

const MessageInfo = styled.div`
  font-size: 12px;
  color: ${({ isOwnMessage }) => (isOwnMessage ? '#A0C1FF' : '#888888')};
  text-align: ${({ isOwnMessage }) => (isOwnMessage ? 'right' : 'left')};
`;

const InputContainer = styled.div`
  display: flex;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const NicknameModal = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`;

const ChatComponent = ({ gameId, topic1, topic2, initialNickname }) => {
  const [messages, setMessages] = useState([]);
  const [messageContent, setMessageContent] = useState('');
  const [stompClient, setStompClient] = useState(null);
  const [showModal, setShowModal] = useState(!initialNickname);
  const [nickname, setNickname] = useState(initialNickname || '익명');
  const [nicknameInput, setNicknameInput] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (nickname) {
      fetchMessages();
      connect();
    }
    return () => {
      if (stompClient) {
        stompClient.deactivate();
      }
    };
  }, [nickname]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/messages/${gameId}`);
      setMessages(response.data);
      localStorage.setItem(`messages_${gameId}`, JSON.stringify(response.data));
    } catch (error) {
      console.error('Failed to fetch messages:', error);
      const savedMessages = localStorage.getItem(`messages_${gameId}`);
      if (savedMessages) {
        setMessages(JSON.parse(savedMessages));
      }
    }
  };

  const connect = () => {
    const client = new Client({
      brokerURL: 'ws://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/ws',
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      webSocketFactory: () => new SockJS('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/ws'),
      onConnect: () => {
        client.subscribe('/topic/messages', (messageOutput) => {
          const message = JSON.parse(messageOutput.body);
          setMessages((prevMessages) => {
            const isNewMessage = !prevMessages.some(msg => msg.id === message.id);
            if (isNewMessage) {
              const updatedMessages = [...prevMessages, message];
              localStorage.setItem(`messages_${gameId}`, JSON.stringify(updatedMessages));
              return updatedMessages;
            }
            return prevMessages;
          });
        });
      },
      onStompError: (frame) => {
        console.error('Broker reported error: ' + frame.headers['message']);
        console.error('Additional details: ' + frame.body);
      },
    });
    client.activate();
    setStompClient(client);
  };

  const sendMessage = () => {
    if (messageContent.trim() === '' || nickname.trim() === '') return;
    const message = {
      content: messageContent.trim(),
      nickname: nickname,
      gameId: gameId,
      timestamp: new Date().toISOString()  // 현재 시간을 ISO 문자열로 추가
    };
    if (stompClient) {
      stompClient.publish({ destination: '/app/chat', body: JSON.stringify(message) });
    }
    setMessageContent('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();  // 기본 Enter 동작 방지
      sendMessage();
    }
  };

  const handleSetNickname = async () => {
    let newNickname = nicknameInput.trim() === '' ? '익명' : nicknameInput.trim();
    let nicknameExists = false;
    let count = 1;

    do {
      nicknameExists = messages.some(message => message.nickname === newNickname);
      if (nicknameExists) {
        newNickname = `익명${count}`;
        count++;
      }
    } while (nicknameExists);

    setNickname(newNickname);
    localStorage.setItem(`nickname_${gameId}`, newNickname);
    setShowModal(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const savedNickname = localStorage.getItem(`nickname_${gameId}`);
    if (savedNickname) {
      setNickname(savedNickname);
      setShowModal(false);
    }
  }, [gameId]);

  return (
    <ChatContainer>
      <ChatHeader>{topic1} VS {topic2}</ChatHeader>
      <NicknameModal show={showModal}>
        <ModalContent>
          <h2>공지</h2>
          <p>실시간 채팅은 서로서로를 위해<br />익명으로만 가능합니다:)</p>
          <Button onClick={handleSetNickname}>확인</Button>
        </ModalContent>
      </NicknameModal>
      <MessagesContainer>
        {messages.map((message, index) => (
          <MessageBubble key={index} isOwnMessage={message.nickname === nickname}>
            <MessageContent isOwnMessage={message.nickname === nickname}>
              <MessageInfo isOwnMessage={message.nickname === nickname}>
                {message.nickname} • {new Date(message.timestamp).toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
              </MessageInfo>
              {message.content}
            </MessageContent>
          </MessageBubble>
        ))}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputContainer>
        <Input
          type="text"
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          placeholder="메시지를 입력하세요..."
          onKeyDown={handleKeyDown}
        />
        <Button onClick={sendMessage}>보내기</Button>
      </InputContainer>
    </ChatContainer>
  );
};

export default ChatComponent;
