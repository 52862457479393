import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #ffffff; // 배경색을 흰색으로 설정합니다.
  padding: 10px 20px;
  text-align: center;
  color: #2c3e50; // 텍스트 색상을 어두운 색으로 설정합니다.
  border-top: 2px solid #969696;
`;

const StyledLink = styled.a`
  color: #2c3e50; // 링크 텍스트 색상
  text-decoration: none; // 밑줄 없애기
  &:hover {
    color: #FF5675; // 링크에 마우스를 올렸을 때 색상 변화
    text-decoration: underline; // 링크에 마우스를 올렸을 때 밑줄 추가
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p>&copy; 2024 FATELY. All rights reserved.</p>
      <p>광고 및 후원 문의 : <StyledLink href="mailto:ghdtmdgml3510@gmail.com">ghdtmdgml3510@gmail.com</StyledLink></p>
      <p>인스타그램 : <StyledLink href="https://www.instagram.com/fately.co.kr?igsh=bXd0Y2dyeHFnZzJ5&utm_source=qr" target="_blank" rel="noopener noreferrer">@fately.co.kr</StyledLink></p>
    </FooterContainer>
  );
};

export default Footer;
