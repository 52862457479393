// src/styles.js
import styled from 'styled-components';
import Card from '../components/Card'; // Card 컴포넌트 임포트

export const HomeContainer = styled.div`
  min-height: 100vh;
  position: relative;
  padding: 20px;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
  z-index: 1;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #FF5675;
    outline: none;
  }
`;

export const Select = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #FF5675;
    outline: none;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #FF7A85;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #FF5675;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
`;

export const CardContainer = styled.div`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
`;

export const CardStyle = styled(Card)`
  height: 300px; // 고정 높이 설정
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ShareButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px; /* 아이콘 사이 간격 추가 */
`;

export const ResponseContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const ResponseBoxContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ResponseBox = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

export const ResponseText = styled.p`
  font-size: 1.2em;
  color: #666;
  line-height: 1.6;
  font-family: 'Nanum Gothic', sans-serif;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

// 새로 추가된 스타일
export const WeatherIconContainer = styled.div`
  width: 80px;
  height: 80px;
  background-color: #87CEEB; /* 하늘색 배경 */
  border-radius: 50%; /* 둥근 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* 아이콘과 타이틀 사이에 여백 추가 */
  img {
    width: 50px;
    height: 50px;
  } 
`;

export const WeatherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`;

export const WeatherTitle = styled.div`
  font-size: 1.0em;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const GraphContainer = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 20px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
`;

export const TextSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  p {
    margin: 5px 10px;
  }
`;

export const SkinAnalysisContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`;

export const SkinAnalysisTable = styled.table`
  width: 100%;
  max-width: 600px;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const SkinAnalysisRow = styled.tr`
  border-bottom: 1px solid #ccc;
`;

export const SkinAnalysisCell = styled.td`
  padding: 10px;
  text-align: center;
  color: #333;
`;

export const SkinAnalysisHeader = styled.th`
  padding: 10px;
  background-color: #f8f8f8;
  color: #333;
  font-weight: bold;
  text-align: center;
`;