import React, { useEffect } from 'react';
import styled from 'styled-components';

const FooterAdContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const FooterAdComponent = ({ position }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//t1.daumcdn.net/kas/static/ba.min.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <FooterAdContainer position={position}>
     <div>
          <ins className="kakao_ad_area" 
            style={{ display: 'block' }} 
            data-ad-unit="DAN-eZmJYVyi6qMRAqwm" 
            data-ad-width="250" 
            data-ad-height="250"></ins>
        </div>
    </FooterAdContainer>
  );
};

export default FooterAdComponent;
