import React, { useState, useEffect } from 'react';
import { FacebookShareButton, TwitterShareButton, EmailShareButton, FacebookIcon, TwitterIcon, EmailIcon } from 'react-share';
import styled from 'styled-components';
import 카카오톡 from '../assets/카카오톡.png';
import 인스타그램 from '../assets/instagram.png'; // 인스타그램 아이콘 경로
import 공유하기 from '../assets/share.png'; // 공유 아이콘 경로

export const KakaoButton = styled.button`
  background: #F3EC2A;
  border: none;
  border-radius: 50%; /* 동그랗게 만들기 */
  padding: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const KakaoIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%; /* 이미지도 동그랗게 만들기 */
`;

export const CustomIconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* 말풍선을 배치하기 위해 상대 위치로 설정 */
`;

export const CustomIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: 40px; /* 아이콘 위에 말풍선을 배치하기 위해 아래쪽에 여유를 둠 */
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s;
`;

export const ShareButtonGroup = ({ url, title, handleKakaoShare, handleInstagramShare, handleUrlCopy }) => {
  const [tooltipVisible, setTooltipVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTooltipVisible(false);
    }, 30000); // 30초 후에 말풍선 숨기기

    return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 정리
  }, []);

  return (
    <div style={{ display: 'flex', gap: '10px', position: 'relative' }}>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <EmailShareButton url={url} subject={title} body="Check out this page: ">
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
      <CustomIconButton
        onClick={handleKakaoShare}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <Tooltip visible={tooltipVisible}>친구에게 공유해보세요!</Tooltip>
        <KakaoIcon src={카카오톡} alt="카카오톡 공유" />
      </CustomIconButton>
      <CustomIconButton
        onClick={handleInstagramShare}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <CustomIcon src={인스타그램} alt="인스타그램 공유" />
      </CustomIconButton>
      <CustomIconButton
        onClick={handleUrlCopy}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <CustomIcon src={공유하기} alt="링크 복사" />
      </CustomIconButton>
    </div>
  );
};
