import React from 'react';
import Modal from 'react-modal';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '400px',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
};

const AlertModal = ({ isOpen, onRequestClose, message }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={customModalStyles}
    contentLabel="Alert Modal"
  >
    <h2>알림</h2>
    <p dangerouslySetInnerHTML={{ __html: message }}></p>
    <button onClick={onRequestClose} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007BFF', color: '#fff', border: 'none', cursor: 'pointer' }}>확인</button>
  </Modal>
);

export default AlertModal;
