// src/data/cards.js
import 고민상담 from '../assets/고민상담.png';
import mbti from '../assets/mbti.png';
import 연애궁합 from '../assets/연애궁합.png';
import 꿈해몽 from '../assets/꿈해몽.png';
import 오늘의운세 from '../assets/오늘의운세.png';
import 오늘의연애운 from '../assets/오늘의연애운.png';
import 결혼사주 from '../assets/결혼사주.png';
import 재물사주 from '../assets/재물사주.png';
import 직업사주 from '../assets/직업사주.png';
import 십이간지운세 from '../assets/십이간지운세.png';
import 십이간지재물 from '../assets/십이간지재물.png';
import 십이간지띠성격 from '../assets/십이간지띠성격.png';

const cards = [
  {
    title: "[십이간지 운세]",
    description: "내 띠의 운세가 궁금하다면?",
    to: "/zodiac_fortune",
    backgroundImage: 십이간지운세,
  },
  {
    title: "[십이간지 재물운]",
    description: "오늘의 재물운을 확인해보세요!",
    to: "/zodiac_wealth",
    backgroundImage: 십이간지재물,
  },
  {
    title: "[십이간지 성격]",
    description: "내 띠의 성격은 어떨까?",
    to: "/zodiac_personality",
    backgroundImage: 십이간지띠성격,
  },
  {
    title: "[결혼 사주]",
    description: "내 운명의 짝은 누구?",
    to: "/marriage-fortune",
    backgroundImage: 결혼사주,
  },
  {
    title: "[재물 사주]",
    description: "재물운이 궁금하다면 클릭!",
    to: "/wealth-fortune",
    backgroundImage: 재물사주,
  },
  {
    title: "[직업 사주]",
    description: "내 직업운은 어떤지 알아보세요.",
    to: "/career-fortune",
    backgroundImage: 직업사주,
  },
  {
    title: "[YOUR SECRET NOTE]",
    description: "고민이 있다면 말해보세요.",
    to: "/counsel",
    backgroundImage: 고민상담,
  },
  {
    title: "[MBTI별 운세]",
    description: "MBTI로 보는 운세!",
    to: "/mbti_fortune",
    backgroundImage: mbti,
  },
  {
    title: "[연애궁합]",
    description: "우리의 궁합은 어떨까?",
    to: "/love-compatibility",
    backgroundImage: 연애궁합,
  },
  {
    title: "[꿈 해몽]",
    description: "꿈의 의미를 해석해드립니다.",
    to: "/dream-interpretation",
    backgroundImage: 꿈해몽,
  },
  {
    title: "[오늘의 운세]",
    description: "오늘의 운세를 확인해보세요!",
    to: "/daily-fortune",
    backgroundImage: 오늘의운세,
  },
  {
    title: "[오늘의 연애운]",
    description: "오늘의 연애운을 확인해보세요.",
    to: "/love-fortune",
    backgroundImage: 오늘의연애운,
  },
];

export default cards;
