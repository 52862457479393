import React, { useState, useEffect } from 'react';
import GameCard from '../components/GameCard';
import styled from 'styled-components';
import Modal from 'react-modal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const BalanceMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-size: cover;
  background-attachment: fixed;
`;

const MenuTitle = styled.h3`
  font-family: 'Nanum Gothic', sans-serif;
  color: #333;
  margin-bottom: 10px;
  text-align: left;  
  width: 100%;
  max-width: 1200px; 
  padding-left: 35px;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const BalanceMenu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hideForToday, setHideForToday] = useState(false);
  const [cards, setCards] = useState([]);
  const [visitorCounts, setVisitorCounts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchGames();
    const now = new Date().getTime();

    const hideUntil = localStorage.getItem('hideModalUntil');
    if (!hideUntil || now > new Date(parseInt(hideUntil)).getTime()) {
      setIsModalOpen(true);
    }

    // Load visitor counts from localStorage or generate new ones if expired
    const visitorData = localStorage.getItem('visitorData');
    if (visitorData) {
      const parsedData = JSON.parse(visitorData);
      const lastUpdated = parsedData.timestamp;
      if (now - lastUpdated < 3600000) { // 1 hour in milliseconds
        setVisitorCounts(parsedData.counts);
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (cards.length > 0) {
      generateVisitorCounts();
    }
  }, [cards]);

  const fetchGames = async () => {
    try {
      const response = await axios.get('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/gameList');
      console.log(response);
      const games = response.data.map(game => {
        const hashedName = CryptoJS.SHA256(game.nickname).toString();
        return {
          title: `${game.topic1} VS ${game.topic2}`,
          description: game.description,
          to: `/game/${game.id}/${hashedName}`,
          backgroundImage: game.image
        };
      });

      setCards(games);
    } catch (error) {
      console.error('Failed to fetch games', error);
    }
  };

  const generateVisitorCounts = () => {
    const newCounts = {};
    cards.forEach(card => {
      newCounts[card.to] = Math.floor(Math.random() * 200) + 1;
    });
    const visitorData = {
      counts: newCounts,
      timestamp: new Date().getTime()
    };
    localStorage.setItem('visitorData', JSON.stringify(visitorData));
    setVisitorCounts(newCounts);
  };

  const closeModal = () => {
    if (hideForToday) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      localStorage.setItem('hideModalUntil', tomorrow.getTime().toString());
    }
    setIsModalOpen(false);
  };

  return (
    <BalanceMenuContainer>
      <MenuTitle>FATELY의 벨런스게임</MenuTitle>
      <CardsContainer>
        {cards.map((card, index) => (
          <GameCard
            key={index}
            title={card.title}
            description={card.description}
            to={card.to}
            backgroundImage={card.backgroundImage}
            visitorCount={visitorCounts[card.to] || 5}
          />
        ))}
      </CardsContainer>
    </BalanceMenuContainer>
  );
};

export default BalanceMenu;
