import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  ResponseContainer, ResponseBoxContainer, ResponseBox, Title, ResponseText, 
  ButtonContainer, Button, SliderContainer, CardContainer, CardStyle, ShareButtons, 
  WeatherIconContainer, WeatherWrapper, WeatherTitle, ImageContainer, GraphContainer 
} from '../data/responseStyles';
import { ShareButtonGroup } from '../data/responseIcons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, Cell,LabelList } from 'recharts';
import cards from '../data/cards'; // 카드 설정 파일 임포트
import happinessStamp from '../assets/행복.png';
import neutralStamp from '../assets/중립.png';
import sadnessStamp from '../assets/슬픔.png';
import angerStamp from '../assets/분노.png';
import surpriseStamp from '../assets/놀람.png';
import fearStamp from '../assets/두려움.png';
import disgustStamp from '../assets/혐오.png';
import faceCards from '../data/faceCards';


const LATITUDE = '37.5665';  // 위치의 위도 (서울의 위도 예시)
const LONGITUDE = '126.9780';  // 위치의 경도 (서울의 경도 예시)

const FaceAnalysisResponsePage = () => {
  const [shuffledCards, setCards] = useState([]);
  const [weatherDescription, setWeatherDescription] = useState('');
  const [weatherIcon, setWeatherIcon] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const navigate = useNavigate();

  const { id, hashedName } = useParams();
  const [response, setResponse] = useState('');
  const [userName, setUserName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [analysisResult, setAnalysisResult] = useState(null);

  useEffect(() => {
    const fetchResponse = async () => {
      try {
        const res = await axios.get(`https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/faceAnalysis/${id}/${hashedName}`);
        const data = res.data;

        setResponse(data.chatGptResponse);
        setUserName(data.userName);
        setImageUrl(data.imageUrl);

        // JSON 데이터를 올바르게 변환
        const analysisResult = JSON.parse(data.analysisResult);

        if (analysisResult && analysisResult.faces && analysisResult.faces.length > 0) {
          const emotions = analysisResult.faces[0].attributes.emotion;
          setAnalysisResult(emotions);
        }
      } catch (error) {
        console.error('Error fetching face analysis response', error);
      }
    };

    const fetchWeather = async () => {
      try {
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${LATITUDE}&lon=${LONGITUDE}&appid=${process.env.REACT_APP_WEATHER_API_KEY}&units=metric`;
        const res = await axios.get(url);
        const weatherDescription = res.data.weather[0].description.toLowerCase();
        const weatherIcon = res.data.weather[0].icon;
        setWeatherDescription(weatherDescription);
        setWeatherIcon(weatherIcon);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    const fetchBackgroundImage = async (weatherDescription) => {
      try {
        const now = new Date();
        const hours = now.getHours();
        const timeOfDay = hours >= 6 && hours < 18 ? 'day' : 'night';
        const query = `${weatherDescription} ${timeOfDay}`;
        const url = `https://api.unsplash.com/search/photos?query=${query}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}&per_page=1`;
        const res = await axios.get(url);
        if (res.data.results && res.data.results.length > 0) {
          setBackgroundImage(res.data.results[0].urls.full);
        } else {
          setBackgroundImage('/images/default.jpg');
        }
      } catch (error) {
        console.error("Error fetching background image:", error);
      }
    };
    fetchResponse();
    fetchWeather();

  },  [id, hashedName]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const kakaoKey = process.env.REACT_APP_KAKAO_API_KEY;
    if (kakaoKey && !window.Kakao.isInitialized()) {
      window.Kakao.init(kakaoKey); // 카카오 앱 키로 초기화
    }

    fetchCardCounts();
  }, []);

  const fetchCardCounts = async () => {
    try {
      const response = await axios.get('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/cards/counts');
      const counts = response.data;

      console.log('Counts from backend:', counts);

      let sortedCards = [...cards].sort((a, b) => {
        const countA = counts[a.to] || 0;
        const countB = counts[b.to] || 0;
        return countB - countA;
      });

       // 복사된 객체를 사용하여 제목을 수정
       sortedCards = sortedCards.map((card, index) => {
        const newCard = { ...card }; // 새로운 객체 생성
        if (index < 3) {
          newCard.title = `[👑BEST${index + 1}👑] ${newCard.title}`;
        }
        return newCard;
      });

      // 페이스 카드와 사주 카드를 나누어 추가
      setCards([...faceCards, ...sortedCards]);
    } catch (error) {
      console.error("Failed to fetch card counts", error);
    }
  };

  const handleRetry = () => {
    navigate('/face-analysis'); // 여기서 URL을 설정하여 페이지 이동
  };
  const handleMemu = () => {
    navigate('/'); // 여기서 URL을 설정하여 페이지 이동
  };
  const shareTitle = `${userName}님의 얼굴 감정 분석 결과는..?`;
  const shareDescription = `${userName}님, 얼굴 감정 분석 결과를 확인해보세요!`;
  const shareImageUrl = imageUrl; // 공유할 이미지 URL
  const shareUrl = window.location.href; // 현재 페이지 URL 가져오기

  const handleShare = () => {
    navigator.share({
      title: shareTitle,
      text: shareDescription,
      url: shareUrl,
    });
  };

  const handleKakaoShare = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: shareTitle,
        description: shareDescription,
        imageUrl: shareImageUrl, // 공유할 이미지 URL
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
      buttons: [
        {
          title: '자세히 보기',
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
      ],
    });
  };

  const settings = {
    dots: false, // 점 표시 없애기
    infinite: true,
    speed: 500,
    slidesToShow: 3, // 기본 설정: 한 줄에 3개의 카드
    slidesToScroll: 1, // 기본 설정: 한 번에 1개의 카드 스크롤
    arrows: true,
    autoplay: true, // 자동 슬라이드 넘김
    autoplaySpeed: 4000, // 자동 슬라이드 넘김 속도 (4초)
    responsive: [
      {
        breakpoint: 768, // 화면이 768px 이하일 때
        settings: {
          slidesToShow: 1, // 한 줄에 1개의 카드
          slidesToScroll: 1, // 한 번에 1개의 카드 스크롤
          arrows: false, // 모바일에서 화살표 숨기기 (선택사항)
        }
      }
    ]
  };

  const handleFacebookShare = () => {
    const shareUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareDescription)}`, 'facebook-share-dialog', 'width=800,height=600');
  };

  const handleTwitterShare = () => {
    const shareUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareDescription)}`, 'twitter-share-dialog', 'width=800,height=600');
  };

  const handleEmailShare = () => {
    const shareUrl = window.location.href;
    window.location.href = `mailto:?subject=${encodeURIComponent(shareTitle)}&body=${encodeURIComponent(`${shareDescription} 링크: ${shareUrl}`)}`;
  };

  const handleInstagramShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('URL이 복사되었습니다.');
    });
  };
  
  const handleUrlCopy = () => {
    const shareUrl = window.location.href;
    navigator.share({
      title: shareTitle,
      text: shareDescription,
      url: shareUrl,
    });
  };

  const formatResponseText = (text) => {
    // **을 <strong>으로 변환하고, 줄바꿈을 <br>로 변환
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\n/g, '<br />');

    // -로 시작하는 라인을 <ul>과 <li>로 변환
    formattedText = formattedText.replace(/^- (.*)$/gm, '<li>$1</li>');

    // <li>가 있으면 <ul>로 감싸기
    if (formattedText.includes('<li>')) {
      formattedText = formattedText.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>');
    }

    return { __html: formattedText };
  };

  const data = analysisResult
  ? [
      { name: '행복😀', value: analysisResult.happiness },
      { name: '중립🙂', value: analysisResult.neutral },
      { name: '슬픔😭', value: analysisResult.sadness },
      { name: '혐오🤮', value: analysisResult.disgust },
      { name: '분노🤬', value: analysisResult.anger },
      { name: '놀람😲', value: analysisResult.surprise },
      { name: '두려움😨', value: analysisResult.fear },
    ]
  : [];

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57', '#a4de6c', '#8dd1e1'];

const highestEmotion = analysisResult
  ? Object.keys(analysisResult).reduce((a, b) => analysisResult[a] > analysisResult[b] ? a : b)
  : null;

const emotionStamps = {
  happiness: happinessStamp,
  neutral: neutralStamp,
  sadness: sadnessStamp,
  disgust: disgustStamp,
  anger: angerStamp,
  surprise: surpriseStamp,
  fear: fearStamp,
};

const highestEmotionStamp = highestEmotion ? emotionStamps[highestEmotion] : null;

return (
  <ResponseContainer /* style={{ backgroundImage: `url(${backgroundImage})` }} */>
    <ResponseBoxContainer>
      <WeatherWrapper>
        <WeatherTitle>오늘의 날씨</WeatherTitle>
        {weatherIcon && (
          <WeatherIconContainer>
            <img src={`/icons/${weatherIcon}.png`} alt={weatherDescription} />
          </WeatherIconContainer>
        )}
      </WeatherWrapper>
      <ResponseBox>
        <Title>{`${userName}님의 얼굴감정분석 결과는?`}</Title>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <ImageContainer style={{ position: 'relative' }}>
            {imageUrl ? (
              <img src={imageUrl} alt="Uploaded" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            ) : (
              "이미지"
            )}
            {highestEmotionStamp && (
              <img
                src={highestEmotionStamp}
                alt="Emotion Stamp"
                style={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-25px',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%'
                }}
              />
            )}
          </ImageContainer>
          <GraphContainer width="100%">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 100]} />
                <YAxis type="category" dataKey="name" />
                <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
                <Bar dataKey="value" fill="#000">
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                  <LabelList 
                    dataKey="value" 
                    position="insideRight" 
                    formatter={(value) => `${value.toFixed(1)}%`} 
                    style={{ fontSize: '15px', fontWeight: 'bold', fill: '#000' }} 
                    content={(props) => {
                      const { x, y, width, value, index } = props;
                      const textAnchor = width > 40 ? 'end' : 'start';
                      const offsetX = width > 40 ? -10 : 10;
                      return (
                        <text
                          x={x + width + offsetX}
                          y={y + 20}
                          textAnchor={textAnchor}
                          style={{ fontSize: '15px', fontWeight: 'bold', fill: '#000' }}
                        >
                          {value.toFixed(1)}%
                        </text>
                      );
                    }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </GraphContainer>
        </div>
        <ResponseText dangerouslySetInnerHTML={formatResponseText(response)} />
      </ResponseBox>
      <ButtonContainer>
        <Button onClick={handleMemu}>⟵ 다른사주 보러가기</Button>
        <Button onClick={handleRetry}>다시하기 ⟳</Button>
        <Button onClick={handleShare}>결과공유하기</Button>
      </ButtonContainer>
      <ShareButtons>
        <ShareButtonGroup
          url={shareUrl}
          title={shareTitle}
          handleKakaoShare={handleKakaoShare}
          handleFacebookShare={handleFacebookShare}
          handleTwitterShare={handleTwitterShare}
          handleEmailShare={handleEmailShare}
          handleInstagramShare={handleInstagramShare}
          handleUrlCopy={handleUrlCopy}
        />
      </ShareButtons>
    </ResponseBoxContainer>
    <SliderContainer>
      <Slider {...settings}>
        {shuffledCards.map((card, index) => (
          <CardContainer key={index}>
            <CardStyle 
              title={card.title} 
              description={card.description} 
              to={card.to} 
              backgroundImage={card.backgroundImage} 
            />
          </CardContainer>
        ))}
      </Slider>
    </SliderContainer>
  </ResponseContainer>
);
};

export default FaceAnalysisResponsePage;
