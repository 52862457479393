// src/data/faceCards.js
import 표정분석 from '../assets/표정분석.png';
import 퍼스널컬러 from '../assets/퍼스널컬러.png';

const faceCards = [
   {
    title: "[NEW][얼굴 감정 분석]",
    description: "내 얼굴이 어떤 감정인지 확인해보세요!",
    to: "/face-analysis",
    backgroundImage: 표정분석,
  },
  {
    title: "[NEW][퍼스널 컬러 진단]",
    description: "퍼스널컬러를 지금 확인해보세요!",
    to: "/face-skinAnalysis",
    backgroundImage: 퍼스널컬러,
  }, 
];

export default faceCards;
