import styled from 'styled-components';
import Card from '../components/Card'; // Card 컴포넌트 임포트

export const HomeContainer = styled.div`
  min-height: 100vh;
  position: relative;
  padding: 20px;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
  z-index: 1;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #FF5675;
    outline: none;
  }
`;

export const Select = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #FF5675;
    outline: none;
  }
`;

export const Textarea = styled.textarea`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: none;
  &:focus {
    border-color: #FF5675;
    outline: none;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #FF7A85;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #FF5675;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
`;

export const CardContainer = styled.div`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
`;

export const CardStyle = styled(Card)`
  height: 300px; // 고정 높이 설정
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ShareButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px; /* 아이콘 사이 간격 추가 */
`;

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    padding: '20px',
    borderRadius: '10px',
    color: '#000', // 글씨 색상을 검은색으로 설정
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

export const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  border: 2px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #999;
`;

export const UploadButton = styled.button`
  width: 300px;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const UploadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
`;
