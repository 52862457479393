import React, { useState, useEffect } from 'react';
import Card from '../components/Card';
import styled from 'styled-components';
import cardsData from '../data/cards';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FortuneMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-size: cover;
  background-attachment: fixed;
`;

const MenuTitle = styled.h3`
  font-family: 'Nanum Gothic', sans-serif;
  color: #333;
  margin-bottom: 10px;
  text-align: left;  
  width: 100%;
  max-width: 1200px; 
  padding-left: 35px;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // 모바일에서는 한 줄에 한 개의 카드만 보이도록 설정
    gap: 10px;
  }
`;

const ModalContent = styled.div`
  margin: 20px;
  text-align: center;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #FF7A85;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #FF5675;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px; /* 버튼 사이에 10px 간격을 추가합니다 */
`;

const FortuneMenu = () => {
  const [cards, setCards] = useState([]);
  const [visitorCounts, setVisitorCounts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCardCounts();
    const now = new Date().getTime();

    const hideUntil = localStorage.getItem('hideModalUntil');
    if (!hideUntil || new Date().getTime() > new Date(hideUntil).getTime()) { 
    }

     // Load visitor counts from localStorage or generate new ones if expired
     const visitorData = localStorage.getItem('visitorData');
     if (visitorData) {
       const parsedData = JSON.parse(visitorData);
       const lastUpdated = parsedData.timestamp;
       if (now - lastUpdated < 3600000) { // 1 hour in milliseconds
         setVisitorCounts(parsedData.counts);
         return;
       }
     }
  }, []);

  useEffect(() => {
    if (cards.length > 0) {
      generateVisitorCounts();
    }
  }, [cards]);

  const fetchCardCounts = async () => {
    try {
      const response = await axios.get('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/cards/counts');
      const counts = response.data;

      let sortedCards = [...cardsData].sort((a, b) => {
        const countA = counts[a.to] || 0;
        const countB = counts[b.to] || 0;
        return countB - countA;
      });

      // 복사된 객체를 사용하여 제목을 수정
      sortedCards = sortedCards.map((card, index) => {
        const newCard = { ...card }; // 새로운 객체 생성
        if (index < 3) {
          newCard.title = `[👑BEST${index + 1}👑] ${newCard.title}`;
        }
        return newCard;
      });

      setCards([...sortedCards]);
    } catch (error) {
      console.error("Failed to fetch card counts", error);
    }
  };

  const generateVisitorCounts = () => {
    const newCounts = {};
    cards.forEach(card => {
      newCounts[card.to] = Math.floor(Math.random() * 200) + 1;
    });
    const visitorData = {
      counts: newCounts,
      timestamp: new Date().getTime()
    };
    localStorage.setItem('visitorData', JSON.stringify(visitorData));
    setVisitorCounts(newCounts);
  };

  return (
    <FortuneMenuContainer>
      <MenuTitle>FATELY의 사주</MenuTitle>
      <CardsContainer>
        {cards.filter(card => !card.to.includes('face')).map((card, index) => (
          <Card 
            key={index}
            title={card.title}
            description={card.description}
            to={card.to}
            backgroundImage={card.backgroundImage}
            visitorCount={visitorCounts[card.to] || 5}
          />
        ))}
      </CardsContainer>
    </FortuneMenuContainer>
  );
};

export default FortuneMenu;
