import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardContainer = styled.div`
  border-radius: 15px;
  padding: 0;
  margin: 10px 0;
  width: 90%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  background: #fff;
  font-family: 'Nanum Gothic', sans-serif;

  &:hover {
    transform: scale(1.05);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  padding-top: 56.25%; /* 16:9 비율 */
  background: url(${props => props.backgroundImage}) no-repeat center center;
  background-size: cover;
`;

const ContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 80px;
`;

const CardTitle = styled.p`
  margin: 0;
  font-size: 1.0em;
  color: #000;
  font-weight: bold;
`;

const CardDescription = styled.p`
  margin: 10px 0 0;
  font-size: 1.0em;
  color: #000;
`;

const VisitorCount = styled.p`
  margin: 5px 0px;
  font-size: 0.8em;
  color: #999;
  text-align: right;
`;

const Card = ({ title, description, to, backgroundImage, visitorCount }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none', width: '100%', display: 'flex', justifyContent: 'center' }}>
      <CardContainer>
        <ImageContainer backgroundImage={backgroundImage} />
        <ContentContainer>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
          <VisitorCount>최근 1시간 접속자 수: {visitorCount}명</VisitorCount>
        </ContentContainer>
      </CardContainer>
    </Link>
  );
};

export default Card;
