import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 30px; /* 패딩을 조금 더 키웠습니다 */
  border-radius: 20px; /* 모서리를 둥글게 했습니다 */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 220px; /* 가로와 세로 길이를 동일하게 했습니다 */
  height: 140px; /* 가로와 세로 길이를 동일하게 했습니다 */
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(FaSpinner)`
  font-size: 2em;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 20px; /* 아이콘과 텍스트 사이에 간격을 추가했습니다 */
`;

const LoadingText = styled.p`
  margin: 0;
  font-size: 1.2em; /* 텍스트 크기를 조금 키웠습니다 */
  color: #333;
`;

const LoadingModal = () => {
  return (
    <ModalBackground>
      <ModalContent>
        <Spinner />
        <LoadingText>FATELY가 게임을 만들고 있습니다.<br /> 조금만 기다려주세요.☺️</LoadingText>
      </ModalContent>
    </ModalBackground>
  );
};

export default LoadingModal;
