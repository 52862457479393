import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import Modal from 'react-modal';
import ChatComponent from './ChatComponent'; // Import ChatComponent
import { ShareButtonGroup } from '../data/responseIcons';
import { ShareButtons } from '../data/responseStyles';
import { Helmet } from 'react-helmet';

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
  z-index: 1;
`;

const GameHeader = styled.div`
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
`;

const GameTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
`;

const GameMetadataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const GameMetadataRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const GameMetadataCell = styled.th`
  padding: 8px;
  text-align: left;
  font-size: 16px;
  color: #555;
`;

const GameMetadata = styled.td`
  padding: 8px;
  text-align: left;
  font-size: 16px;
  color: #555;
`;

const GameDescription = styled.p`
  font-size: 18px;
  color: #555;
  font-weight: bold;
`;

const TopicContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
`;

const TopicBox = styled.div`
  flex: 1;
  border: 2px solid #ccc;
  padding: 20px;
  text-align: center;
  margin: 0 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${({ selected, color }) => (selected ? color : 'white')};
  color: ${({ selected }) => (selected ? 'white' : 'black')};
`;

const GameImage = styled.img`
  width: 80%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const ModalContent = styled.div`
  text-align: center;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const BalanceGameDetail = () => {
  const { id, hashedName } = useParams();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(true);
  const [voteCounts, setVoteCounts] = useState({ topicCount1: 0, topicCount2: 0 });
  const [voted, setVoted] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const chatRef = useRef(null); // ChatComponent에 ref 추가

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지가 로드될 때 맨 위로 스크롤

    fetchGame();

    const kakaoKey = process.env.REACT_APP_KAKAO_API_KEY;
    if (kakaoKey && !window.Kakao.isInitialized()) {
      window.Kakao.init(kakaoKey); // 카카오 앱 키로 초기화
    }

    // 페이지 처음 로드되거나 새로고침할 때 맨 위로 스크롤
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('load', handleScrollToTop);
    return () => window.removeEventListener('load', handleScrollToTop);
  }, [id, hashedName]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); // 채팅 컴포넌트가 로드될 때 맨 위로 스크롤
    }
  }, [game]); // game 상태가 변경될 때만 실행

  const fetchGame = async () => {
    try {
      const response = await axios.get(`https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/games/${id}/${hashedName}`);
      setGame(response.data);
      setVoteCounts({ topicCount1: response.data.topicCount1, topicCount2: response.data.topicCount2 });
    } catch (error) {
      console.error('게임 정보를 불러오는 중 오류가 발생했습니다.', error);
    } finally {
      setLoading(false);
    }
  };

  const vote = async (topic) => {
    if (voted) {
      setIsModalOpen(true);
      return;
    }

    try {
      const response = await axios.post(`https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/games/${id}/vote/${topic}`);
      if (response.status === 200) {
        setSelectedTopic(topic);
        setVoted(true);
        if (topic === 'topic1') {
          setVoteCounts((prev) => ({ ...prev, topicCount1: prev.topicCount1 + 1 }));
        } else if (topic === 'topic2') {
          setVoteCounts((prev) => ({ ...prev, topicCount2: prev.topicCount2 + 1 }));
        }
      }
    } catch (error) {
      console.error('투표 중 오류가 발생했습니다.', error);
    }
  };

  if (loading) {
    return <GameContainer>로딩 중...</GameContainer>;
  }

  if (!game) {
    return <GameContainer>게임 정보를 불러오지 못했습니다.</GameContainer>;
  }

  const shareTitle = `${game.topic1} VS ${game.topic2}`;
  const shareDescription = '벨런스 게임에 같이 참여해봐요!';
  const shareImageUrl = `${game.image}`;
  const shareUrl = window.location.href; // 현재 페이지 URL 가져오기

  const handleKakaoShare = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: shareTitle,
        description: shareDescription,
        imageUrl: shareImageUrl, // 공유할 이미지 URL
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
      buttons: [
        {
          title: '자세히 보기',
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
      ],
    });
  };

  const handleFacebookShare = () => {
    const shareUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareDescription)}`, 'facebook-share-dialog', 'width=800,height=600');
  };

  const handleTwitterShare = () => {
    const shareUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareDescription)}`, 'twitter-share-dialog', 'width=800,height=600');
  };

  const handleEmailShare = () => {
    const shareUrl = window.location.href;
    window.location.href = `mailto:?subject=${encodeURIComponent(shareTitle)}&body=${encodeURIComponent(`${shareDescription} 링크: ${shareUrl}`)}`;
  };

  const handleInstagramShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('URL이 복사되었습니다.');
    });
  };

  const handleUrlCopy = () => {
    const shareUrl = window.location.href;
    navigator.share({
      title: shareTitle,
      text: shareDescription,
      url: shareUrl,
    });
  };

  const totalVotes = voteCounts.topicCount1 + voteCounts.topicCount2;
  const topic1Percentage = totalVotes > 0 ? ((voteCounts.topicCount1 / totalVotes) * 100).toFixed(1) : 0;
  const topic2Percentage = totalVotes > 0 ? ((voteCounts.topicCount2 / totalVotes) * 100).toFixed(1) : 0;

  return (
    <GameContainer>
      <Helmet>
        <title>벨런스 게임 참여 (참여 아이디 : {id})</title>
        <meta property="og:title" content="벨런스 게임 참여 - FATELY" />
        <meta property="og:description" content="FATELY에서 벨런스 게임을 만들고 참여해보세요!" />
      </Helmet>
      <GameHeader>
        <GameMetadataTable>
          <tbody>
            <GameMetadataRow>
              <GameMetadataCell>주제 :</GameMetadataCell>
              <GameMetadata>{game.description}</GameMetadata>
            </GameMetadataRow>
            <GameMetadataRow>
              <GameMetadataCell>작성자 :</GameMetadataCell>
              <GameMetadata>{game.nickname}</GameMetadata>
            </GameMetadataRow>
            <GameMetadataRow>
              <GameMetadataCell>생성시간 :</GameMetadataCell>
              <GameMetadata>{new Date(game.timestamp).toLocaleString()}</GameMetadata>
            </GameMetadataRow>
          </tbody>
        </GameMetadataTable>
      </GameHeader>
      {game.image && <GameImage src={game.image} alt={`${game.topic1} VS ${game.topic2}`} />}
      <GameTitle>{game.topic1} VS {game.topic2}</GameTitle>
      <TopicContainer>
        <TopicBox
          onClick={() => vote('topic1')}
          selected={voted && selectedTopic === 'topic1'}
          color="#FF7F7F"
        >
          {voted ? `${game.topic1} (${topic1Percentage}%)` : `${game.topic1}?`}
        </TopicBox>
        <TopicBox
          onClick={() => vote('topic2')}
          selected={voted && selectedTopic === 'topic2'}
          color="#7F7FFF"
        >
          {voted ? `${game.topic2} (${topic2Percentage}%)` : `${game.topic2}?`}
        </TopicBox>
      </TopicContainer>
      <ShareButtons>
        <ShareButtonGroup
          url={shareUrl}
          title={shareTitle}
          handleKakaoShare={handleKakaoShare}
          handleFacebookShare={handleFacebookShare}
          handleTwitterShare={handleTwitterShare}
          handleEmailShare={handleEmailShare}
          handleInstagramShare={handleInstagramShare}
          handleUrlCopy={handleUrlCopy}
        />
      </ShareButtons>
      <ChatComponent gameId={id} topic1={game.topic1} topic2={game.topic2} initialNickname="" ref={chatRef}/>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="투표 알림"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            maxWidth: '400px',
            width: '80%',
            borderRadius: '10px',
            textAlign: 'center',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <ModalContent>
          <ModalTitle>투표 완료</ModalTitle>
          <p>이미 투표하셨습니다.</p>
          <ModalButton onClick={() => setIsModalOpen(false)}>닫기</ModalButton>
        </ModalContent>
      </Modal>
    </GameContainer>
  );
};

export default BalanceGameDetail;
