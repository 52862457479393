import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const FormContainer = styled.div`
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #FF5675;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #FF7A85;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #FF5675;
  }
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/login', {
        email,
        password,
      }, {
        withCredentials: true
      });
      console.log(response.data);
      setMessage('로그인 성공!');
      navigate('/');
    } catch (error) {
      console.error('로그인 중 오류가 발생했습니다.', error);
      setMessage('이메일 또는 패스워드가 잘못되었습니다.');
    }
  };

  return (
    <LoginContainer>
      <FormContainer>
        <Title>로그인</Title>
        <Form onSubmit={handleSubmit}>
          <Input 
            type="email" 
            value={email} 
            placeholder="이메일" 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          <Input 
            type="password" 
            value={password} 
            placeholder="패스워드" 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <Button type="submit">로그인하기</Button>
        </Form>
        {message && <p>{message}</p>}
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
