import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Counsel from './components/Counsel';
import MBTIFortune from './components/MBTIFortune';
import LoveCompatibility from './components/LoveCompatibility';
import DreamInterpretation from './components/DreamInterpretation';
import DailyFortune from './components/DailyFortune';
import WealthFortune from './components/WealthFortune';
import MarriageFortune from './components/MarriageFortune';
import CareerFortune from './components/CareerFortune';
import LoveFortune from './components/LoveFortune';
import CounselResponsePage from './components/CounselResponsePage';
import DailyResponsePage from './components/DailyResponsePage';
import WealthResponsePage from './components/WealthResponsePage';
import MarriageResponsePage from './components/MarriageResponsePage';
import CareerResponsePage from './components/CareerResponsePage';
import DreamInterpretationResponsePage from './components/DreamInterpretationResponsePage';
import LoveCompatibilityResponsePage from './components/LoveCompatibilityResponsePage';
import LoveFortuneResponsePage from './components/LoveFortuneResponsePage';
import MBTIFortuneResponsePage from './components/MBTIFortuneResponsePage';
import ZodiacPersonality from './components/ZodiacPersonality';
import ZodiacPersonalityResponsePage from './components/ZodiacPersonalityResponsePage';
import ZodiacFortune from './components/ZodiacFortune';
import ZodiacFortuneResponsePage from './components/ZodiacFortuneResponsePage';
import ZodiacWealth from './components/ZodiacWealth';
import ZodiacWealthResponsePage from './components/ZodiacWealthResponsePage';
import Header from './components/Header';
import Footer from './components/Footer';
import MainMenu from './pages/MainMenu';
import FaceMenu from './pages/FaceMenu';
import FortuneMenu from './pages/FortuneMenu';
import BellanceMenu from './pages/BellanceMenu';
import SignUp from './components/SignUp';
import Login from './components/Login';
import BalanceGameDetail from './components/BalanceGameDetail';
import FaceAnalysis from './components/FaceAnalysis';
import BalanceGameForm from './components/BalanceGameForm';
import FaceAnalysisResponsePage from './components/FaceAnalysisResponsePage';
import FaceSkinAnalysis from './components/FaceSkinAnalysis';
import FaceSkinAnalysisResponsePage from './components/FaceSkinAnalysisResponsePage';
import AdComponent from './components/AdComponent';
import FooterAdComponent from './components/FooterAdComponent';

const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Router>
      <Header />
      <AdComponent isMobile={isMobile} position="top" />
      <Routes>
        <Route path="/" element={<MainMenu />} />
        <Route path="/faceMenu" element={<FaceMenu />} />
        <Route path="/fortuneMenu" element={<FortuneMenu />} />
        <Route path="/bellanceMenu" element={<BellanceMenu />} />
        <Route path="/bellanceGame" element={<BalanceGameForm />} />
        <Route path="/game/:id/:hashedName" element={<BalanceGameDetail />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/counsel" element={<Counsel />} />
        <Route path="/mbti_fortune" element={<MBTIFortune />} />
        <Route path="/love-compatibility" element={<LoveCompatibility />} />
        <Route path="/dream-interpretation" element={<DreamInterpretation />} />
        <Route path="/daily-fortune" element={<DailyFortune />} />
        <Route path="/wealth-fortune" element={<WealthFortune />} />
        <Route path="/marriage-fortune" element={<MarriageFortune />} />
        <Route path="/career-fortune" element={<CareerFortune />} />
        <Route path="/love-fortune" element={<LoveFortune />} />
        <Route path="/counselResponse/:id/:hashedName" element={<CounselResponsePage />} />
        <Route path="/dailyResponse/:id/:hashedName" element={<DailyResponsePage />} />
        <Route path="/wealthResponse/:id/:hashedName" element={<WealthResponsePage />} />
        <Route path="/marriageResponse/:id/:hashedName" element={<MarriageResponsePage />} />
        <Route path="/careerResponse/:id/:hashedName" element={<CareerResponsePage />} />
        <Route path="/dreamInterpretationResponse/:id/:hashedName" element={<DreamInterpretationResponsePage />} />
        <Route path="/loveCompatibilityResponse/:id/:hashedFemaleName/:hashedMaleName" element={<LoveCompatibilityResponsePage />} />
        <Route path="/loveFortuneResponse/:id/:hashedName" element={<LoveFortuneResponsePage />} />
        <Route path="/MBTIFortuneResponse/:id/:hashedName" element={<MBTIFortuneResponsePage />} />
        <Route path="/zodiac_personality" element={<ZodiacPersonality />} />
        <Route path="/zodiacPersonalityResponse/:id/:hashedName" element={<ZodiacPersonalityResponsePage />} />
        <Route path="/zodiac_fortune" element={<ZodiacFortune />} />
        <Route path="/zodiacFortuneResponse/:id/:hashedName" element={<ZodiacFortuneResponsePage />} />
        <Route path="/zodiac_wealth" element={<ZodiacWealth />} />
        <Route path="/zodiacWealthResponse/:id/:hashedName" element={<ZodiacWealthResponsePage />} />
        <Route path="/face-analysis" element={<FaceAnalysis />} /> 
        <Route path="/face-analysisResponse/:id/:hashedName" element={<FaceAnalysisResponsePage />} /> 
        <Route path="/face-skinAnalysis" element={<FaceSkinAnalysis />} /> 
        <Route path="/face-skinAnalysisResponse/:id/:hashedName" element={<FaceSkinAnalysisResponsePage />} /> 
      </Routes>
      <FooterAdComponent position="bottom" />
      <Footer />
    </Router>
  );
};

export default App;
