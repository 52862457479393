import React, { useState, useEffect } from 'react';
import Card from '../components/Card';
import styled from 'styled-components';
import cardsData from '../data/cards';
import faceCards from '../data/faceCards';
import Modal from 'react-modal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MainMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-size: cover;
  background-attachment: fixed;
`;

const MenuTitle = styled.h3`
  font-family: 'Nanum Gothic', sans-serif;
  color: #333;
  margin-bottom: 10px;
  text-align: left;  
  width: 100%;
  max-width: 1200px; 
  padding-left: 35px;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // 모바일에서는 한 줄에 한 개의 카드만 보이도록 설정
    gap: 10px;
  }
`;

const ModalContent = styled.div`
  margin: 20px;
  text-align: center;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #FF7A85;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #FF5675;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px; /* 버튼 사이에 10px 간격을 추가합니다 */
`;

const MainMenu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  const [hideForToday, setHideForToday] = useState(false);
  const [cards, setCards] = useState([]);
  const [visitorCounts, setVisitorCounts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCardCounts();
    
    const hideUntil = localStorage.getItem('hideModalUntil');
    const hideMenuUntil = localStorage.getItem('hideMenuModalUntil');
    const now = new Date().getTime();

    if (!hideUntil || now > new Date(parseInt(hideUntil)).getTime()) { 
      setIsModalOpen(true);
    }

    if (!hideMenuUntil || now > new Date(parseInt(hideMenuUntil)).getTime()) {
      setIsMenuModalOpen(true);
    }

    // Load visitor counts from localStorage or generate new ones if expired
    const visitorData = localStorage.getItem('visitorData');
    if (visitorData) {
      const parsedData = JSON.parse(visitorData);
      const lastUpdated = parsedData.timestamp;
      if (now - lastUpdated < 3600000) { // 1 hour in milliseconds
        setVisitorCounts(parsedData.counts);
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (cards.length > 0) {
      generateVisitorCounts();
    }
  }, [cards]);

  const fetchCardCounts = async () => {
    try {
      const response = await axios.get('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/cards/counts');
      const counts = response.data;

      let sortedCards = [...cardsData].sort((a, b) => {
        const countA = counts[a.to] || 0;
        const countB = counts[b.to] || 0;
        return countB - countA;
      });

      // 복사된 객체를 사용하여 제목을 수정
      sortedCards = sortedCards.map((card, index) => {
        const newCard = { ...card }; // 새로운 객체 생성
        if (index < 3) {
          newCard.title = `[👑BEST${index + 1}👑] ${newCard.title}`;
        }
        return newCard;
      });

      // 페이스 카드와 사주 카드를 나누어 추가
      setCards([...faceCards, ...sortedCards]);
    } catch (error) {
      console.error("Failed to fetch card counts", error);
    }
  };

  const generateVisitorCounts = () => {
    const newCounts = {};
    [...cardsData, ...faceCards].forEach(card => {
      newCounts[card.to] = Math.floor(Math.random() * 200) + 1;
    });
    const visitorData = {
      counts: newCounts,
      timestamp: new Date().getTime()
    };
    localStorage.setItem('visitorData', JSON.stringify(visitorData));
    setVisitorCounts(newCounts);
  };

  const closeModal = () => {
    if (hideForToday) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      localStorage.setItem('hideModalUntil', tomorrow.getTime().toString());
    }
    setIsModalOpen(false);
  };

  const closeMenuModal = () => {
    const inOneHour = new Date();
    inOneHour.setHours(inOneHour.getHours() + 1);
    localStorage.setItem('hideMenuModalUntil', inOneHour.getTime().toString());
    setIsMenuModalOpen(false);
  };

  return (
    <MainMenuContainer>
      {/* <MenuTitle>FATELY의 페이스 진단</MenuTitle>
      <CardsContainer>
        {cards.filter(card => card.to.includes('face')).map((card, index) => (
          <Card 
            key={index}
            title={card.title}
            description={card.description}
            to={card.to}
            backgroundImage={card.backgroundImage}
            visitorCount={visitorCounts[card.to] || 5}
          />
        ))}
      </CardsContainer> */}
      <MenuTitle>FATELY의 사주</MenuTitle>
      <CardsContainer>
        {cards.filter(card => !card.to.includes('face')).map((card, index) => (
          <Card 
            key={index}
            title={card.title}
            description={card.description}
            to={card.to}
            backgroundImage={card.backgroundImage}
            visitorCount={visitorCounts[card.to] || 5}
          />
        ))}
      </CardsContainer>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Popup Modal"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '10px',
            maxWidth: '90%',
            width: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <CloseButton onClick={closeModal}>×</CloseButton>
        <ModalContent>
          <h2>환영합니다!</h2>
          <p>오늘의 특별한 사주 서비스를 무료로 확인해보세요!<br />저희 FATELY는 AI 기반으로 사주풀이를 무료로 진행하고 있습니다.<br />꾸준히 신규 사주 및 재밌는 테스트를 업데이트할 예정입니다.<br />재미로만 봐주세요 :)</p>
          <CheckboxContainer>
            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <input
                type="checkbox"
                checked={hideForToday}
                onChange={() => {
                  setHideForToday(!hideForToday);
                }}
                style={{ marginRight: '8px' }}
              />
              오늘 하루 보지 않기
            </label>
          </CheckboxContainer>
          <ModalButton onClick={closeModal}>확인</ModalButton>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isMenuModalOpen}
        onRequestClose={closeMenuModal}
        contentLabel="Menu Modal"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '10px',
            maxWidth: '90%',
            width: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <CloseButton onClick={closeMenuModal}>×</CloseButton>
        <ModalContent>
          <h2>공지</h2>
          <p>FATELY에서 벨런스 게임 기능을 출시했습니다!<br />실시간으로 논쟁에 참여할 수 있고 벨런스 게임을 직접 만드실 수 있습니다!😀</p>
          <ButtonContainer>
            <ModalButton onClick={() => navigate('/bellanceGame')}>벨런스 게임 만들기</ModalButton>
            <ModalButton onClick={() => navigate('/bellanceMenu')}>벨런스 게임 리스트</ModalButton>
          </ButtonContainer>
        </ModalContent>
      </Modal>
    </MainMenuContainer>
  );
};

export default MainMenu;
