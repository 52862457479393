import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const HomeContainer = styled.div`
  min-height: 100vh;
  position: relative;
  padding: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    border-color: #FF5675;
    outline: none;
  }
`;

const CheckNicknameButton = styled.button`
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #FF7A85;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #FF5675;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #FF7A85;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #FF5675;
  }
`;

const Message = styled.p`
  color: ${props => props.isAvailable ? 'green' : 'red'};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  h2 {
    margin-top: 10px;
    font-size: 18px;
    color: #333;
  }

  svg {
    font-size: 50px;
    color: ${props => (props.success ? '#4CAF50' : '#FF4C4C')};
  }
`;

const SignUp = () => {
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nicknameMessage, setNicknameMessage] = useState('');
  const [isNicknameChecked, setIsNicknameChecked] = useState(false);
  const [isNicknameAvailable, setIsNicknameAvailable] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  const handleNicknameCheck = async () => {
    try {
      const response = await axios.get('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/check-nickname', {
        params: { nickname }
      });
      const isAvailable = !response.data;
      setNicknameMessage(isAvailable ? '사용 가능한 닉네임입니다.' : '이미 사용 중인 닉네임입니다.');
      setIsNicknameChecked(true);
      setIsNicknameAvailable(isAvailable);
    } catch (error) {
      setNicknameMessage('닉네임 중복 체크 중 오류가 발생했습니다.');
      setIsNicknameChecked(true);
      setIsNicknameAvailable(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isNicknameChecked || !isNicknameAvailable) {
      setNicknameMessage('닉네임 중복체크를 해주세요.');
      return;
    }

    try {
      const response = await axios.post('https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/user', {
        nickname,
        email,
        password,
      });
      console.log(response.data);
      setModalMessage('회원가입에 성공했습니다!');
      setIsSuccess(true);
      setModalIsOpen(true);
      setTimeout(() => {
        setModalIsOpen(false);
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('회원가입 중 오류가 발생했습니다.', error);
      setModalMessage('회원가입 중 오류가 발생했습니다.');
      setIsSuccess(false);
      setModalIsOpen(true);
    }
  };

  return (
    <HomeContainer>
      <FormContainer>
        <Title>회원가입</Title>
        <Form onSubmit={handleSubmit}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input 
              type="text" 
              value={nickname} 
              placeholder="닉네임" 
              onChange={(e) => {
                setNickname(e.target.value);
                setIsNicknameChecked(false);
                setNicknameMessage('');
              }} 
              required 
            />
            <CheckNicknameButton type="button" onClick={handleNicknameCheck}>중복체크</CheckNicknameButton>
          </div>
          {nicknameMessage && <Message isAvailable={isNicknameAvailable}>{nicknameMessage}</Message>}
          <Input 
            type="email" 
            value={email} 
            placeholder="이메일" 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          <Input 
            type="password" 
            value={password} 
            placeholder="패스워드" 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <Button type="submit">회원가입하기</Button>
        </Form>
      </FormContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="회원가입 결과"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            textAlign: 'center',
          },
        }}
      >
        <ModalContent success={isSuccess}>
          {isSuccess ? <FaCheckCircle /> : <FaTimesCircle />}
          <h2>{modalMessage}</h2>
        </ModalContent>
      </Modal>
    </HomeContainer>
  );
};

export default SignUp;
